export enum TrackType {
    CTA = 'Call_to_action',
    PurchaseComplete = 'Purchase_complete',
    ViewDashboard = 'View_dashboard',
}

export enum TrackAction {
    ViewMore = 'View_more',
    ClickedQuestion = 'Clicked_question_',
    PrinterSelect = 'Printer_selected_',
    PlanSelected = 'Plan_selected_',
    PaperAdded = 'Paper_added_',
    OpenedComparisonTable = 'Opened_comparison_table',
    ClosedComparisonTable = 'Closed_comparison_table',
    NeedMorePlanModalOpen = 'Opened_Need_More_Plan_Info_modal',
    WhyAddPaperModalOpen = 'Opened_Why_add_paper_modal',
    NeedMorePlanModalClose = 'Closed_Need_More_Plan_Info_modal',
    WhyAddPaperModalClose = 'Closed_Why_add_paper_modal',
    ContinueCheckout = 'Continued_to_checkout',
    Survey = 'Survey',

    ShippingModalOpened = 'Opened_shipping_modal',
    ShippingModalClosed = 'Closed_shipping_modal',

    BillingModalOpened = 'Opened_billing_modal',
    BillingModalClosed = 'Closed_billing_modal',

    ARNModalOpened = 'Opened_ARN_modal',
    ARNModalClosed = 'Closed_ARN_modal',

    SurveyModalOpened = 'Opened_Survey_modal',
    SurveyModalClosed = 'Closed_Survey_modal',

    SayanNotAvailableModalOpened = 'Opened_SayanNotAvailable_modal',
    SayanNotAvailableModalClosed = 'Closed_SayanNotAvailable_modal',
    SayanNotAvailableModalBuyNowButton = 'Redirected_to_buy_now',
    SayanNotAvailableModalPrinterSelection = 'Redirected_to_printer_selection',

    ShippingModalSubmitted = 'Submitted_shipping_info',
    BillingModalSubmitted = 'Submitted_billing_info',

    SunsetClickedLearnMore = 'Sunset_Clicked_Learn_More',
    AnniversarySunsetClickedLearnMore = 'Anniversary_Sunset_Clicked_Learn_More',

    SpainSunsetClickedDiscoverMore = 'Spain_Sunset_Clicked_Discover_More',
    SpainSunsetClickedBuy = 'Spain_Sunset_Clicked_Buy',
}

export enum TrackLocation {
    Home = 'home',
    TermsAndServices = 'terms',
    PrinterSelection = 'printerSelection',
    Hero = 'hero',
    RiskFree = 'riskfree',
    Subscription = 'subscription',
    Steps = 'steps',
    FAQ = 'faq',
    Disclaimers = 'disclaimers',
    Upgrades = 'upgrades',
    Enviroment = 'enviroment',
    Values = 'values',
    PurchaseComplete = 'complete',
    SunsetLanding = 'sunsetLanding',
    Checkout = 'checkout',
    RenewalNotice = 'renewalnotice',
    UpgradeLanding = 'upgradeLanding',
    UpgradePrinterSelection = 'upgradePrinterSelection',
}
