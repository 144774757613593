import PrinterImg from 'assets/printer.png';
import { Button, NavigationBar } from 'components';
import { ButtonVariants } from 'components/CustomButton/constants';
import { TrackAction } from 'constants/analytics';
import useRedirectOutside from 'screens/AnniversarySunset/AnniversarySunset.hooks';
import { Footer } from 'screens/LandingPage/components';

import left from './assets/left.png';
import right from './assets/right.png';

import styles from './SpainSunset.module.scss';

function SpainSunset() {
    const redirectOutside = useRedirectOutside();

    const handleDiscoverMore = () => {
        redirectOutside(
            'https://instantink.hpconnected.com/es/es/l/v2?utm_source=Platinum&utm_medium=Sunset&utm_campaign=Oct2024',
            TrackAction.SpainSunsetClickedDiscoverMore
        );
    };

    const handleBuy = () => {
        redirectOutside(
            'https://www.hp.com/es-es/shop/list.aspx?fc_seg_home=1&sel=prn&utm_source=Platinum&utm_medium=Sunset&utm_campaign=Oct2024',
            TrackAction.SpainSunsetClickedBuy
        );
    };

    return (
        <div className={styles.container}>
            <NavigationBar />
            <header className={styles.header}>
                <h1>Gracias por tu interés en Platinum</h1>
                <p>En estos momentos no aceptamos nuevas suscripciones</p>
                <img src={PrinterImg} alt="Printer" />
            </header>

            <main className={styles.main}>
                <div className={styles.leftContainer}>
                    <div className={styles.topSection}>
                        <img src={left} alt="Sunset" className={styles.image} />
                    </div>
                    <div className={`${styles.bottomSection} ${styles.left}`}>
                        <h2>Compra la mejor impresora para tu hogar</h2>
                        <p>
                            Te facilitamos la impresión en casa para que puedas
                            centrarte en lo que te apasiona
                        </p>

                        <Button
                            variant={ButtonVariants.square}
                            onClick={handleBuy}
                        >
                            Comprar
                        </Button>
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={`${styles.topSection} ${styles.right}`}>
                        <h2>
                            Despreocúpate de los cartuchos para siempre y ahorra
                            hasta un 70% en tinta con el servicio HP Instant Ink
                        </h2>
                        <p>
                            Regístrate en nuestro servicio HP Instant Ink y
                            recibe automáticamente los cartuchos para tu
                            impresora antes de que se agoten
                        </p>

                        <Button
                            variant={ButtonVariants.square}
                            onClick={handleDiscoverMore}
                        >
                            Descubre Más
                        </Button>
                    </div>
                    <div className={styles.bottomSection}>
                        <img
                            src={right}
                            alt="Sunset"
                            className={styles.image}
                        />
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
}
export default SpainSunset;
