import check from 'assets/check.svg';
import printer from 'assets/printer.png';
import { Button } from 'components';
import { ButtonVariants } from 'components/CustomButton/constants';
import { TrackAction } from 'constants/analytics';
import { useFormat } from 'hooks';
import { AnniversarySunsetMessages } from 'lang/messages/anniversarySunset';
import { Footer } from 'screens/LandingPage/components';
import Accordeon from 'screens/LandingPage/components/FAQs/components/Accordeon';

import useRedirectOutside from './AnniversarySunset.hooks';
import { Block } from './components';
import { BLOCKS, FAQS, LEARN_MORE_LINK } from './constants';

import styles from './AnniversarySunset.module.scss';

function AnniversarySunset() {
    const text = useFormat(AnniversarySunsetMessages);
    const redirectOutside = useRedirectOutside();

    return (
        <div>
            <header className={styles.headerContainer}>
                <h1>{text.TITLE}</h1>
                <img src={printer} alt={text.TITLE} />
            </header>

            <main className={styles.mainContainer}>
                <p>{text.BODY}</p>

                <h2>{text.SUBTITLE}</h2>

                <div className={styles.blockContainer}>
                    {BLOCKS(text).map((item) => (
                        <Block item={item} />
                    ))}
                </div>

                <div className={styles.ctaContainer}>
                    <Button
                        variant={ButtonVariants.square}
                        className={styles.button}
                        onClick={() => {
                            redirectOutside(
                                LEARN_MORE_LINK,
                                TrackAction.AnniversarySunsetClickedLearnMore
                            );
                        }}
                    >
                        {text.CTA_BUTTON}
                    </Button>
                    <p className={styles.ctaSubtitle}>{text.CTA_SUBTITLE}</p>

                    <ul>
                        <li>
                            <img src={check} alt="" className={styles.check} />
                            {text.CTA_ITEM_ONE}
                        </li>
                        <li>
                            <img src={check} alt="" className={styles.check} />
                            {text.CTA_ITEM_TWO}
                        </li>
                        <li>
                            <img src={check} alt="" className={styles.check} />
                            {text.CTA_ITEM_THREE}
                        </li>
                    </ul>
                </div>
            </main>

            <section className={styles.faqs}>
                <h3>{text.FAQ_TITLE}</h3>

                <div className={styles.accordionContainer}>
                    {FAQS.map(({ id, answer, question }) => (
                        <Accordeon
                            id={String(id)}
                            content={answer}
                            title={question}
                            key={id}
                        />
                    ))}
                </div>
            </section>

            <div className={styles.disclaimers}>
                <h3>{text.DISCLAIMER_TITLE}</h3>
                <hr />
                <ul>
                    <li>{text.DISCLAIMER_ITEM_ONE}</li>
                    <li>{text.DISCLAIMER_ITEM_TWO}</li>
                    <li>{text.DISCLAIMER_ITEM_THREE}</li>
                </ul>
            </div>

            {/* <footer className={styles.footerContainer}>
                <button type="button" onClick={redirectToTerms}>
                    {text.TERMS}
                </button>
                {brkpoint !== 'xs' && ' | '}
                <button type="button" onClick={redirectToFAQ}>
                    {text.FAQ}
                </button>
            </footer> */}
            <Footer />
        </div>
    );
}
export default AnniversarySunset;
