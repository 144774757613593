import { ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import ScrollToTop from 'components/ScrollToTop';
import { CheckoutModalsProvider } from 'contexts/CheckoutModals';
import DataProvider from 'contexts/DataProvider';
import IntlConfigurator from 'contexts/IntlConfigurator';
import OobeCheckProvider from 'contexts/OobeCheck';
import PrinterChoiceProvider from 'contexts/PrinterChoice';
import { UpgradeInfoProvider } from 'contexts/UpgradeInfo';
import UserInfoProvider from 'contexts/UserInfo';
import UtmStorageProvider from 'contexts/UTMStorage';
import { OrderProvider } from 'hooks/useOrder';

import RandomVariationAssignator from '../RandomVariationAssignator/RandomVariationAssignator';
import TrackVariationInit from '../TrackVariationInit/TrackVariationInit';

interface Props {
    children: ReactNode;
}

function ContextWrapper({ children }: Props) {
    return (
        <UtmStorageProvider>
            <ScrollToTop />
            <PrinterChoiceProvider>
                <DataProvider>
                    <OrderProvider>
                        <IntlConfigurator>
                            <CheckoutModalsProvider>
                                <OobeCheckProvider>
                                    <UserInfoProvider>
                                        <CookiesProvider>
                                            <UpgradeInfoProvider>
                                                <TrackVariationInit>
                                                    <RandomVariationAssignator>
                                                        {children}
                                                    </RandomVariationAssignator>
                                                </TrackVariationInit>
                                            </UpgradeInfoProvider>
                                        </CookiesProvider>
                                    </UserInfoProvider>
                                </OobeCheckProvider>
                            </CheckoutModalsProvider>
                        </IntlConfigurator>
                    </OrderProvider>
                </DataProvider>
            </PrinterChoiceProvider>
        </UtmStorageProvider>
    );
}
export default ContextWrapper;
